import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../database';

function FlashcardEdit() {
  const { id } = useParams(); // Get flashcard ID from the URL
  const navigate = useNavigate();

  const [flashcard, setFlashcard] = useState({
    question: '',
    answers: [{ text: '', isCorrect: true }],
    answerType: 'single',
    images: [],
    links: [],
    category: ''
  });

  // Load the flashcard for editing
  useEffect(() => {
    async function loadFlashcard() {
      const parsedId = parseInt(id, 10);
      if (!isNaN(parsedId)) {
        try {
          const card = await db.get(parsedId); // Get the flashcard from the database
          if (card) {
            setFlashcard(card); // Set the flashcard state for editing
          }
        } catch (error) {
          console.error('Error loading flashcard:', error);
        }
      }
    }
    loadFlashcard();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Filter out empty answers and links
      const flashcardToSave = {
        ...flashcard,
        answers: flashcard.answers.filter(answer => answer.text.trim() !== ''),
        links: flashcard.links.filter(link => link.trim() !== '')
      };

      const parsedId = parseInt(id, 10);
      if (!isNaN(parsedId)) {
        await db.update(parsedId, flashcardToSave); // Update the existing flashcard
        console.log('Flashcard updated with id:', parsedId);
      } else {
        throw new Error('Invalid id');
      }

      // Redirect to the flashcard list page
      navigate('/flashcards');
    } catch (error) {
      console.error('Error saving flashcard:', error);
      alert(`Error saving flashcard: ${error.message}`);
    }
  };

  const handleDelete = async () => {
    const parsedId = parseInt(id, 10);
    if (!isNaN(parsedId) && window.confirm('Are you sure you want to delete this flashcard?')) {
      try {
        await db.delete(parsedId); // Delete the flashcard from the database
        navigate('/flashcards'); // Redirect to the flashcard list after deletion
      } catch (error) {
        console.error('Error deleting flashcard:', error);
      }
    }
  };

  const handleChange = (field, value) => {
    setFlashcard({ ...flashcard, [field]: value });
  };

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...flashcard.answers];
    newAnswers[index].text = value;
    setFlashcard({ ...flashcard, answers: newAnswers });
  };

  const addAnswer = () => {
    setFlashcard({
      ...flashcard,
      answers: [...flashcard.answers, { text: '', isCorrect: false }],
      answerType: 'multiple'
    });
  };

  const removeAnswer = (index) => {
    const newAnswers = flashcard.answers.filter((_, i) => i !== index);
    setFlashcard({
      ...flashcard,
      answers: newAnswers,
      answerType: newAnswers.length > 1 ? 'multiple' : 'single'
    });
  };

  const handleLinkChange = (index, value) => {
    const newLinks = [...flashcard.links];
    newLinks[index] = value;
    setFlashcard({ ...flashcard, links: newLinks });
  };

  const addLink = () => setFlashcard({ ...flashcard, links: [...flashcard.links, ''] });

  const removeLink = (index) => {
    const newLinks = flashcard.links.filter((_, i) => i !== index);
    setFlashcard({ ...flashcard, links: newLinks });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    handleFileUpload(files);
  };

  const handleFileUpload = async (files) => {
    const newImages = await Promise.all(files.map(async (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({
            name: file.name,
            type: file.type,
            data: reader.result // Save file as base64 string
          });
        };
        reader.readAsDataURL(file);
      });
    }));

    setFlashcard((prevFlashcard) => ({
      ...prevFlashcard,
      images: [...prevFlashcard.images, ...newImages]
    }));
  };

  const removeImage = (index) => {
    setFlashcard((prevFlashcard) => ({
      ...prevFlashcard,
      images: prevFlashcard.images.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Edit Flashcard</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Question input */}
        <div>
          <label className="block mb-1 font-medium">Question:</label>
          <input
            type="text"
            value={flashcard.question}
            onChange={(e) => handleChange('question', e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        {/* Answer(s) */}
        <div>
          <label className="block mb-1 font-medium">Answer(s):</label>
          {flashcard.answers.map((answer, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <input
                type="text"
                value={answer.text}
                onChange={(e) => handleAnswerChange(index, e.target.value)}
                className="flex-grow p-2 border rounded"
                required={index === 0}
              />
              {flashcard.answers.length > 1 && (
                <button type="button" onClick={() => removeAnswer(index)} className="text-red-500">
                  Remove
                </button>
              )}
            </div>
          ))}
          <button type="button" onClick={addAnswer} className="text-blue-500">
            Add another answer
          </button>
        </div>

        {/* Image(s) */}
        <div>
          <label className="block mb-1 font-medium">Image(s):</label>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="w-full p-2 border rounded"
          />
          {flashcard.images.map((image, index) => (
            <div key={index} className="mt-2 flex items-center">
              <a href={image.data} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                {image.name}
              </a>
              <button type="button" onClick={() => removeImage(index)} className="ml-2 text-red-500">
                Remove
              </button>
            </div>
          ))}
        </div>

        {/* Link(s) */}
        <div>
          <label className="block mb-1 font-medium">Link(s):</label>
          {flashcard.links.map((link, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <input
                type="url"
                value={link}
                onChange={(e) => handleLinkChange(index, e.target.value)}
                className="flex-grow p-2 border rounded"
                placeholder="https://example.com"
              />
              <button type="button" onClick={() => removeLink(index)} className="text-red-500">
                Remove
              </button>
            </div>
          ))}
          <button type="button" onClick={addLink} className="text-blue-500">
            Add another link
          </button>
        </div>

        {/* Category */}
        <div>
          <label className="block mb-1 font-medium">Deck:</label>
          <input
            type="text"
            value={flashcard.category}
            onChange={(e) => handleChange('category', e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>

        {/* Submit & Delete */}
        <div className="flex justify-between">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            Update Flashcard
          </button>
          <button type="button" onClick={handleDelete} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
            Delete Flashcard
          </button>
        </div>
      </form>
    </div>
  );
}

export default FlashcardEdit;
