import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../database';
import { callAIService } from './AI'; // Assuming this is your AI service

function FlashcardView() {
  const { id } = useParams();
  const [flashcard, setFlashcard] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showHints, setShowHints] = useState([]);
  const [aiResponse, setAiResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function loadFlashcard() {
      const card = await db.getFlashcard(parseInt(id));
      if (card) {
        setFlashcard(card);
        setShowHints(new Array(card.hints.length).fill(false));
      }
    }
    loadFlashcard();
  }, [id]);

  const handleAskAI = async () => {
    setIsLoading(true);
    try {
      const response = await callAIService(flashcard);
      setAiResponse(response);
    } catch (error) {
      console.error('Error calling AI service:', error);
      setAiResponse('Sorry, there was an error generating the AI response.');
    }
    setIsLoading(false);
  };

  const toggleHint = (index) => {
    setShowHints(prev => prev.map((hint, i) => i === index ? !hint : hint));
  };

  if (!flashcard) return <div className="text-center mt-10">Loading...</div>;

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">{flashcard.question}</h2>
      
      <button 
        onClick={() => setShowAnswer(!showAnswer)}
        className="w-full py-2 px-4 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 mb-4"
      >
        {showAnswer ? 'Hide Answer' : 'Show Answer'}
      </button>

      {showAnswer && (
        <div className="mb-4 p-4 bg-gray-100 rounded-md">
          <p>{flashcard.answer}</p>
        </div>
      )}

      <h3 className="font-bold mb-2">Hints:</h3>
      {flashcard.hints.map((hint, index) => (
        <div key={index} className="mb-2">
          <button 
            onClick={() => toggleHint(index)}
            className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {showHints[index] ? `Hide Hint ${index + 1}` : `Show Hint ${index + 1}`}
          </button>
          {showHints[index] && (
            <div className="mt-2 p-4 bg-gray-100 rounded-md">
              {hint}
            </div>
          )}
        </div>
      ))}

      {flashcard.images && flashcard.images.length > 0 && (
        <div className="mt-4">
          <h3 className="font-bold mb-2">Images:</h3>
          <div className="flex flex-wrap">
            {flashcard.images.map((image, index) => (
              <img key={index} src={image.data} alt={`Flashcard visual ${index + 1}`} className="w-1/3 p-1" />
            ))}
          </div>
        </div>
      )}

      {flashcard.documents && flashcard.documents.length > 0 && (
        <div className="mt-4">
          <h3 className="font-bold mb-2">Documents:</h3>
          <ul>
            {flashcard.documents.map((doc, index) => (
              <li key={index}>
                <a href={doc.data} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                  {doc.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {flashcard.links && flashcard.links.length > 0 && (
        <div className="mt-4">
          <h3 className="font-bold mb-2">Links:</h3>
          <ul>
            {flashcard.links.map((link, index) => (
              <li key={index}>
                <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                  {link}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      <button 
        onClick={handleAskAI}
        disabled={isLoading}
        className="w-full mt-4 py-2 px-4 bg-purple-500 text-white rounded-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 disabled:bg-purple-300"
      >
        {isLoading ? 'Generating...' : 'Ask AI for Context'}
      </button>

      {aiResponse && (
        <div className="mt-6 p-4 bg-gray-100 rounded-md">
          <h3 className="font-bold mb-2">AI Response:</h3>
          <p>{aiResponse}</p>
        </div>
      )}

      <div className="mt-6 flex justify-between">
        <Link to="/flashcards" className="text-blue-500 hover:underline">Back to Flashcards</Link>
        <Link to={`/flashcards/edit/${id}`} className="text-blue-500 hover:underline">Edit Flashcard</Link>
        <Link to={`/view/${parseInt(id) + 1}`} className="text-blue-500 hover:underline">Next Flashcard</Link>
      </div>
    </div>
  );
}

export default FlashcardView;