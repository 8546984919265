import React from 'react';
import { Link } from 'react-router-dom';

function DeckItem({ deck }) {
  return (
    <li>
      <h3>{deck.name}</h3>
      <Link to={`/decks/${deck.name}`}>View Deck</Link>
    </li>
  );
}

export default DeckItem;
