import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './components/Homepage';
import FlashcardList from './components/FlashcardList';
import FlashcardNew from './components/FlashcardNew';
import FlashcardEdit from './components/FlashcardEdit';
import FlashcardView from './components/FlashcardView';

import DeckList from './components/decks/DeckList.js';
import DeckNew from './components/decks/DeckNew.js';
import DeckEdit from './components/decks/DeckEdit.js';
import DeckView from './components/decks/DeckView.js';

import AIFlashcardGenerator from './components/AI';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/flashcards" element={<FlashcardList />} />
          <Route path="/flashcards/new" element={<FlashcardNew />} />
          <Route path="/flashcards/edit/:id" element={<FlashcardEdit />} />
          <Route path="/view/:id" element={<FlashcardView />} />

          <Route path="/decks" element={<DeckList />} />
          <Route path="/decks/new" element={<DeckNew />} />
          <Route path="/decks/edit/:id" element={<DeckEdit />} />
          <Route path="/decks/view/:id" element={<DeckView />} />

          <Route path="/ai-generator" element={<AIFlashcardGenerator />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
