import { openDB } from 'idb';

const dbPromise = openDB('flashcardDB', 4, {
  upgrade(db, oldVersion, newVersion, transaction) {
    if (oldVersion < 1) {
      const flashcardStore = db.createObjectStore('flashcards', { keyPath: 'id', autoIncrement: true });
      flashcardStore.createIndex('question', 'question', { unique: false });
      flashcardStore.createIndex('deck', 'deck');
    }
    if (oldVersion < 2) {
      const deckStore = db.createObjectStore('decks', { keyPath: 'name' });
      deckStore.put({ name: 'Default' });
    }
    if (oldVersion < 3) {
      const flashcardStore = transaction.objectStore('flashcards');
      flashcardStore.createIndex('answer', 'answer', { unique: false });
    }
    if (oldVersion < 4) {
      // We don't need to create new indices for arrays (hints, images, documents, links)
      // as they will be stored as part of the flashcard object
      // If we need to perform any operations, we can use:
      // const flashcardStore = transaction.objectStore('flashcards');
    }
  },
});

export const db = {
  // Flashcard functions
  async getAllFlashcards() {
    return (await dbPromise).getAll('flashcards');
  },
  async getFlashcard(id) {
    return (await dbPromise).get('flashcards', id);
  },
  async addFlashcard(flashcard) {
    const db = await dbPromise;
    const tx = db.transaction('flashcards', 'readwrite');
    const store = tx.objectStore('flashcards');
    const id = await store.add(flashcard);
    await tx.done;
    return id;
  },
  async updateFlashcard(id, flashcard) {
    const db = await dbPromise;
    const tx = db.transaction('flashcards', 'readwrite');
    const store = tx.objectStore('flashcards');
    await store.put({ ...flashcard, id });
    await tx.done;
  },
  async deleteFlashcard(id) {
    return (await dbPromise).delete('flashcards', id);
  },

  // Deck functions
  async getAllDecks() {
    return (await dbPromise).getAll('decks');
  },
  async getDeck(name) {
    return (await dbPromise).get('decks', name);
  },
  async addDeck(deck) {
    const db = await dbPromise;
    const tx = db.transaction('decks', 'readwrite');
    const store = tx.objectStore('decks');
    await store.put(deck);
    await tx.done;
  },
  async updateDeck(oldName, newName) {
    if (oldName === 'Default') {
      throw new Error('The Default deck cannot be renamed.');
    }
    const db = await dbPromise;
    const tx = db.transaction(['decks', 'flashcards'], 'readwrite');
    const deckStore = tx.objectStore('decks');
    const flashcardStore = tx.objectStore('flashcards');

    await deckStore.delete(oldName);
    await deckStore.put({ name: newName });

    const index = flashcardStore.index('deck');
    const flashcardsInDeck = await index.getAllKeys(oldName);
    for (const cardId of flashcardsInDeck) {
      const flashcard = await flashcardStore.get(cardId);
      flashcard.deck = newName;
      await flashcardStore.put(flashcard);
    }

    await tx.done;
  },
  async deleteDeck(name) {
    if (name === 'Default') {
      throw new Error('The Default deck cannot be deleted.');
    }

    const db = await dbPromise;
    const tx = db.transaction(['decks', 'flashcards'], 'readwrite');
    const deckStore = tx.objectStore('decks');
    const flashcardStore = tx.objectStore('flashcards');

    const index = flashcardStore.index('deck');
    const flashcardsInDeck = await index.getAllKeys(name);
    for (const cardId of flashcardsInDeck) {
      await flashcardStore.delete(cardId);
    }

    await deckStore.delete(name);
    await tx.done;
  },
};