import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../database';

function FlashcardNew() {
  const navigate = useNavigate();
  
  const [decks, setDecks] = useState([]);
  const [flashcard, setFlashcard] = useState({
    question: '',
    answer: '',
    hints: [''],
    images: [],
    documents: [],
    links: [''],
    deck: 'Default'
  });

  useEffect(() => {
    async function fetchDecks() {
      const allDecks = await db.getAllDecks();
      if (allDecks.length === 0) {
        await db.addDeck({ name: 'Default' });
        setDecks([{ name: 'Default' }]);
      } else {
        setDecks(allDecks);
      }
    }
    fetchDecks();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const flashcardToSave = {
        ...flashcard,
        hints: flashcard.hints.filter(hint => hint.trim() !== ''),
        links: flashcard.links.filter(link => link.trim() !== ''),
        deck: flashcard.deck || 'Default'
      };

      const savedId = await db.addFlashcard(flashcardToSave);
      console.log('New flashcard added with id:', savedId);

      const savedFlashcard = await db.getFlashcard(savedId);
      if (!savedFlashcard) {
        throw new Error('Flashcard was not saved successfully');
      }

      navigate('/flashcards');
    } catch (error) {
      console.error('Error saving flashcard:', error);
      alert(`Error saving flashcard: ${error.message}`);
    }
  };

  const handleChange = (field, value, index = null) => {
    if (field === 'hints' || field === 'links') {
      setFlashcard(prev => ({
        ...prev,
        [field]: prev[field].map((item, i) => i === index ? value : item)
      }));
    } else {
      setFlashcard(prev => ({ ...prev, [field]: value }));
    }
  };

  const addField = (field) => {
    setFlashcard(prev => ({
      ...prev,
      [field]: [...prev[field], '']
    }));
  };

  const removeField = (field, index) => {
    setFlashcard(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  const handleFileChange = (e, type) => {
    const files = Array.from(e.target.files);
    const newFiles = files.map(file => ({
      name: file.name,
      type: file.type,
      data: URL.createObjectURL(file)
    }));
    setFlashcard(prev => ({ ...prev, [type]: [...prev[type], ...newFiles] }));
  };

  const removeFile = (type, index) => {
    setFlashcard(prev => ({
      ...prev,
      [type]: prev[type].filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Create New Flashcard</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1 font-medium">Question:</label>
          <input
            type="text"
            value={flashcard.question}
            onChange={(e) => handleChange('question', e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label className="block mb-1 font-medium">Answer:</label>
          <input
            type="text"
            value={flashcard.answer}
            onChange={(e) => handleChange('answer', e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>

        <div>
          <label className="block mb-1 font-medium">Hint(s):</label>
          {flashcard.hints.map((hint, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <input
                type="text"
                value={hint}
                onChange={(e) => handleChange('hints', e.target.value, index)}
                className="flex-grow p-2 border rounded"
              />
              {flashcard.hints.length > 1 && (
                <button type="button" onClick={() => removeField('hints', index)} className="text-red-500">
                  Remove
                </button>
              )}
            </div>
          ))}
          <button type="button" onClick={() => addField('hints')} className="text-blue-500">
            Add another Hint
          </button>
        </div>

        <div>
          <label className="block mb-1 font-medium">Deck:</label>
          <div className="flex items-center space-x-2">
            <select
              value={flashcard.deck}
              onChange={(e) => handleChange('deck', e.target.value)}
              className="w-full p-2 border rounded"
            >
              {decks.map((deck) => (
                <option key={deck.name} value={deck.name}>
                  {deck.name}
                </option>
              ))}
            </select>
            <button
              type="button"
              onClick={() => navigate('/decks/new')}
              className="text-blue-500"
            >
              Add new deck
            </button>
          </div>
        </div>

        <div>
          <label className="block mb-1 font-medium">Image(s):</label>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={(e) => handleFileChange(e, 'images')}
            className="w-full p-2 border rounded"
          />
          <div className="mt-2 flex flex-wrap">
            {flashcard.images.map((image, index) => (
              <div key={index} className="relative m-1">
                <img src={image.data} alt={`Uploaded ${index + 1}`} className="h-20 w-20 object-cover" />
                <button
                  type="button"
                  onClick={() => removeFile('images', index)}
                  className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>

        <div>
          <label className="block mb-1 font-medium">Document(s):</label>
          <input
            type="file"
            multiple
            onChange={(e) => handleFileChange(e, 'documents')}
            className="w-full p-2 border rounded"
          />
          <ul className="mt-2 list-disc list-inside">
            {flashcard.documents.map((doc, index) => (
              <li key={index} className="flex items-center justify-between">
                <span>{doc.name}</span>
                <button
                  type="button"
                  onClick={() => removeFile('documents', index)}
                  className="text-red-500"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <label className="block mb-1 font-medium">Link(s):</label>
          {flashcard.links.map((link, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <input
                type="url"
                value={link}
                onChange={(e) => handleChange('links', e.target.value, index)}
                className="flex-grow p-2 border rounded"
                placeholder="https://example.com"
              />
              {flashcard.links.length > 1 && (
                <button type="button" onClick={() => removeField('links', index)} className="text-red-500">
                  Remove
                </button>
              )}
            </div>
          ))}
          <button type="button" onClick={() => addField('links')} className="text-blue-500">
            Add another Link
          </button>
        </div>

        <div className="flex justify-between">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            Create Flashcard
          </button>
        </div>
      </form>
    </div>
  );
}

export default FlashcardNew;