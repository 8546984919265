import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../database';

function DeckNew() {
  const navigate = useNavigate();
  const [deckName, setDeckName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (deckName.trim() === '') {
        alert('Deck name cannot be empty');
        return;
      }

      // Ensure the deck name is unique and not "Default"
      const existingDeck = await db.getDeck(deckName);
      if (existingDeck || deckName === 'Default') {
        alert('A deck with this name already exists or it is reserved.');
        return;
      }

      // Add the new deck to the database
      await db.addDeck({ name: deckName });
      navigate('/decks'); // Redirect to the list of decks after creation
    } catch (error) {
      console.error('Error creating new deck:', error);
      alert('Error creating deck: ' + error.message);
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Create New Deck</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="block mb-1 font-medium">Deck Name:</label>
          <input
            type="text"
            value={deckName}
            onChange={(e) => setDeckName(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mt-4">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Create Deck
          </button>
        </div>
      </form>
    </div>
  );
}

export default DeckNew;
