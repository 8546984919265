import React from 'react';
import { Link } from 'react-router-dom';

function Homepage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-8">Flashcard App</h1>
      <div className="space-y-4">
        {/* Updated link to reflect new route */}
        <Link to="/flashcards/new" className="block px-6 py-3 bg-blue-500 text-white rounded-lg text-center hover:bg-blue-600 transition">
          Create New Flashcard
        </Link>
        <Link to="/flashcards" className="block px-6 py-3 bg-green-500 text-white rounded-lg text-center hover:bg-green-600 transition">
          View Flashcards
        </Link>
        <Link to="/decks/new" className="block px-6 py-3 bg-blue-500 text-white rounded-lg text-center hover:bg-blue-600 transition">
          Create New Deck
        </Link>
        <Link to="/decks" className="block px-6 py-3 bg-green-500 text-white rounded-lg text-center hover:bg-green-600 transition">
          View Decks
        </Link>
      </div>
    </div>
  );
}

export default Homepage;
