import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../database';

function DeckView() {
  const { name } = useParams(); // Get the deck name from the URL
  const [deck, setDeck] = useState(null);
  const [flashcards, setFlashcards] = useState([]);

  useEffect(() => {
    async function fetchDeckDetails() {
      const deckDetails = await db.getDeck(name);
      const deckFlashcards = await db.getFlashcardsByDeck(name);
      setDeck(deckDetails);
      setFlashcards(deckFlashcards);
    }
    fetchDeckDetails();
  }, [name]);

  if (!deck) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>{deck.name} - Deck Details</h2>
      <ul>
        {flashcards.map((flashcard) => (
          <li key={flashcard.id}>{flashcard.question}</li>
        ))}
      </ul>
    </div>
  );
}

export default DeckView;
