import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../database';

function FlashcardList() {
  const [flashcards, setFlashcards] = useState([]);
  const [filter, setFilter] = useState('all');
  const [decks, setDecks] = useState([]);

  useEffect(() => {
    async function loadFlashcards() {
      const cards = await db.getAllFlashcards(); // Fetch all flashcards from the updated database
      setFlashcards(cards);
      
      const allDecks = await db.getAllDecks(); // Fetch all decks
      setDecks(allDecks);
    }
    loadFlashcards();
  }, []);

  // Filter flashcards by deck
  const filteredFlashcards = filter === 'all' 
    ? flashcards 
    : flashcards.filter(card => card.deck === filter);

  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-bold mb-6">Flashcards</h2>
      
      <div className="mb-6">
        <label className="block mb-2 font-medium">Filter by Deck:</label>
        <select 
          value={filter} 
          onChange={(e) => setFilter(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="all">All Decks</option>
          {decks.map(deck => (
            <option key={deck.name} value={deck.name}>{deck.name}</option>
          ))}
        </select>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {filteredFlashcards.map((card) => (
          <Link 
            key={card.id}
            to={`/view/${card.id}`}
            className="block p-4 bg-gray-100 rounded-md hover:bg-gray-200 transition duration-150 ease-in-out"
          >
            <h3 className="font-bold mb-2">{card.question}</h3>
            <p className="text-sm text-gray-600">Deck: {card.deck || 'Default'}</p>
          </Link>
        ))}
      </div>

      <Link 
        to="/flashcards/new" 
        className="mt-6 inline-block px-6 py-3 bg-blue-500 text-white rounded-lg text-center hover:bg-blue-600 transition"
      >
        Create New Flashcard
      </Link>
    </div>
  );
}

export default FlashcardList;
