import React, { useState, useEffect } from 'react';
import { db } from '../../database';
import DeckItem from './DeckItem';

function DeckList() {
  const [decks, setDecks] = useState([]);

  useEffect(() => {
    async function fetchDecks() {
      const allDecks = await db.getAllDecks();
      setDecks(allDecks);
    }
    fetchDecks();
  }, []);

  return (
    <div>
      <h2>All Decks</h2>
      <ul>
        {decks.map((deck) => (
          <DeckItem key={deck.name} deck={deck} />
        ))}
      </ul>
    </div>
  );
}

export default DeckList;
