import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../database';

function DeckEdit() {
  const { name } = useParams(); // Get the deck name from the URL
  const [deckName, setDeckName] = useState('');
  const [originalName, setOriginalName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    async function loadDeck() {
      try {
        const deck = await db.getDeck(name);
        if (!deck || name === 'Default') {
          alert('Cannot edit this deck or the deck does not exist.');
          navigate('/decks');
          return;
        }
        setDeckName(deck.name);
        setOriginalName(deck.name);
      } catch (error) {
        console.error('Error loading deck:', error);
      }
    }
    loadDeck();
  }, [name, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (deckName.trim() === '') {
        alert('Deck name cannot be empty');
        return;
      }

      if (deckName === originalName) {
        alert('Deck name has not changed.');
        return;
      }

      const existingDeck = await db.getDeck(deckName);
      if (existingDeck && existingDeck.name !== originalName) {
        alert('A deck with this name already exists.');
        return;
      }

      // Update the deck name in the database
      await db.deleteDeck(originalName); // Delete the old deck entry
      await db.addDeck({ name: deckName }); // Add the new deck name

      navigate('/decks'); // Redirect to the deck list after editing
    } catch (error) {
      console.error('Error editing deck:', error);
      alert('Error editing deck: ' + error.message);
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Edit Deck</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="block mb-1 font-medium">Deck Name:</label>
          <input
            type="text"
            value={deckName}
            onChange={(e) => setDeckName(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mt-4">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
}

export default DeckEdit;
